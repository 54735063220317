import React from 'react';
import {Button, Modal} from 'reactstrap';

import {SupportFormValues} from 'pages/DispatchlandLandingPage/types';

import SupportForm from './Form';

interface OwnProps {
    onClose(): void;
    onSubmit(fields: SupportFormValues): void;
}

const SupportModal = ({onSubmit, onClose}: OwnProps): JSX.Element => {
    return (
        <Modal isOpen={true} className="dspl-modal">
            <div className="dspl-modal__header">
                <div className="dspl-modal__title dspl-modal__title_orange">Get Support</div>
                <Button className="dspl-modal__close" onClick={onClose} color="link">
                    <i className="fas fa-times" />
                </Button>
            </div>
            <SupportForm onSubmit={onSubmit} />
        </Modal>
    );
};

export default SupportModal;
