import TagManager from 'react-gtm-module';

import {fetchAccessForm, fetchSupportForm} from 'config/index';
import * as appActions from 'app/actions';
import * as types from 'pages/DispatchlandLandingPage/actions/actionTypes';

import {sendSupportInfo, sendAccessInfo} from 'pages/DispatchlandLandingPage/requests';

import {allModalsNames} from 'components/modals/modalsMap';

import {SupportFormValues, GetAccessFormValues} from 'pages/DispatchlandLandingPage/types';

const showSuccessInfoModal = () => {
    return function (dispatch: any) {
        const params = {
            name: allModalsNames.successfulModal,
        };
        dispatch(appActions.showModal(params));
    };
};

const showErrorInfoModal = () => {
    return function (dispatch: any) {
        const params = {
            name: allModalsNames.errorModal,
        };
        dispatch(appActions.showModal(params));
    };
};

export function getSupportInfo(data: SupportFormValues): any {
    return function (dispatch: any) {
        dispatch(appActions.showLoaderSpinner());
        sendSupportInfo(data)
            .then((response) => {
                TagManager.dataLayer(fetchSupportForm);
                dispatch({
                    type: types.SET_SUPPORT_INFO,
                    payload: response,
                });
                dispatch(showSuccessInfoModal());
            })
            .catch((error) => {
                console.warn('Error on getting support info: ', error);
                dispatch(showErrorInfoModal());
            })
            .finally(() => dispatch(appActions.hideLoaderSpinner()));
    };
}

export function getAccessInfo(data: GetAccessFormValues): any {
    return function (dispatch: any) {
        dispatch(appActions.showLoaderSpinner());
        sendAccessInfo(data)
            .then((response) => {
                TagManager.dataLayer(fetchAccessForm);
                dispatch({
                    type: types.SET_ACCESS_INFO,
                    payload: response,
                });
                dispatch(showSuccessInfoModal());
            })
            .catch((error) => {
                console.warn('Error on getting access info: ', error);
                dispatch(showErrorInfoModal());
            })
            .finally(() => dispatch(appActions.hideLoaderSpinner()));
    };
}
