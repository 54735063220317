import React from 'react';
import {Button, Modal} from 'reactstrap';
import {useHistory} from 'react-router-dom';

import {GetAccessFormValues} from 'pages/DispatchlandLandingPage/types';

import DeleteForm from './Form';

type OwnProps = {
    onSubmit(fields: GetAccessFormValues): void;
    onClose(): void;
};

const DeleteModal: React.FC<OwnProps> = (props) => {
    const {onSubmit, onClose} = props;
    const history = useHistory();

    const onCloseModal = () => {
        history.push('/');
        onClose();
    };

    return (
        <Modal isOpen={true} className="dspl-modal">
            <div className="dspl-modal__header">
                <div className="dspl-modal__title">Request to delete you account and data</div>
                <Button className="dspl-modal__close" onClick={onCloseModal} color="link">
                    <i className="fas fa-times" />
                </Button>
            </div>
            <DeleteForm onSubmit={onSubmit} />
        </Modal>
    );
};

export default DeleteModal;
