export const SHOW_LOADER_SPINNER = 'SHOW_LOADER_SPINNER';
export const HIDE_LOADER_SPINNER = 'HIDE_LOADER_SPINNER';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export interface ShowModal {
    type: typeof SHOW_MODAL;
    payload: {modalName: string; modalData: any};
}

export interface HideModal {
    type: typeof HIDE_MODAL;
}

export type AppActionTypes = ShowModal | HideModal;
