import React, {Suspense} from 'react';
import Loader from 'components/Loader';
import {Route, Switch} from 'react-router-dom';

const DispatchlandLandingPage = React.lazy(() => import('../pages/DispatchlandLandingPage'));
const NotFoundPage = React.lazy(() => import('../pages/NotFound'));

export default (): JSX.Element => (
    <Suspense fallback={<Loader />}>
        <Switch>
            <Route exact path="/" component={DispatchlandLandingPage} />
            <Route exact path="/delete-account" component={DispatchlandLandingPage} />
            <Route component={NotFoundPage} />
        </Switch>
    </Suspense>
);
