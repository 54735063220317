import React from 'react';
import {Button, Modal} from 'reactstrap';

import {GetAccessFormValues} from 'pages/DispatchlandLandingPage/types';

import GetAccessForm from './Form';

interface OwnProps {
    onClose(): void;
    onSubmit(fields: GetAccessFormValues): void;
}

const SupportModal = ({onSubmit, onClose}: OwnProps): JSX.Element => {
    return (
        <Modal isOpen={true} className="dspl-modal">
            <div className="dspl-modal__header">
                <div className="dspl-modal__title">Get Access</div>
                <Button className="dspl-modal__close" onClick={onClose} color="link">
                    <i className="fas fa-times" />
                </Button>
            </div>
            <GetAccessForm onSubmit={onSubmit} />
        </Modal>
    );
};

export default SupportModal;
