export default {
    BUILD_HASH: process.env.NODE_ENV === 'development' ? '123456789' : (process.env.REACT_APP_BUILD_ID as string),
};

export const openGetSupportModal = {
    dataLayer: {
        event: 'getSupportFormClick',
    },
};

export const openGetAccessModal = {
    dataLayer: {
        event: 'getAccessFormClick',
    },
};

export const fetchAccessForm = {
    dataLayer: {
        event: 'getAccessFormSend',
    },
};

export const fetchSupportForm = {
    dataLayer: {
        event: 'getSupportFormSend',
    },
};

export const initialize = {
    gtmId: 'GTM-WR8V634',
};
