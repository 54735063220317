import React from 'react';

import styles from './loader.module.scss';

const Loader = (): JSX.Element | null => {
    return (
        <div className={styles.wrap}>
            <i className="fas fa-spinner fa-spin" />
        </div>
    );
};

export default Loader;
