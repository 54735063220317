import isFunction from 'lodash/isFunction';

import * as types from 'app/actions/actionTypes';

import globalRegister from 'utils/globalRegister';

export function showLoaderSpinner(): any {
    return {
        type: types.SHOW_LOADER_SPINNER,
    };
}

export function hideLoaderSpinner(): any {
    return {
        type: types.HIDE_LOADER_SPINNER,
    };
}

export function showModal(params: any): any {
    const {name, mode, onSubmit, closeBeforeSubmit, data} = params;

    if (onSubmit && isFunction(onSubmit)) {
        globalRegister.setHandler(onSubmit);
    }

    return {
        type: types.SHOW_MODAL,
        payload: {
            name,
            mode,
            data,
            closeBeforeSubmit,
        },
    };
}

export function hideModal(): any {
    globalRegister.removeHandler();
    return {
        type: types.HIDE_MODAL,
    };
}
