import restApi from 'services/api';

import {GetAccessFormValues, SupportFormValues} from 'pages/DispatchlandLandingPage/types';

export const sendSupportInfo = (data: SupportFormValues): Promise<SupportFormValues> => {
    return restApi.post(`/emails/support`, {
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        companyName: data.companyName,
        questionTopic: data.questionTopic,
        message: data.message,
    });
};

export const sendAccessInfo = (data: GetAccessFormValues): Promise<GetAccessFormValues> => {
    return restApi.post(`/emails/sales`, {
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        companyName: data.companyName,
        message: data.message,
    });
};
