import React from 'react';
import {Button, Modal} from 'reactstrap';

interface OwnProps {
    onClose(): void;
}

const ErrorModal = ({onClose}: OwnProps): JSX.Element => {
    return (
        <Modal isOpen={true} className="dspl-info-modal">
            <div className="dspl-info-modal__header">
                <div className="dspl-info-modal__title dspl-info-modal__title_error">Error</div>
            </div>
            <div className="dspl-info-modal__content">Message was not sent, please try later</div>
            <div className="dspl-info-modal__footer">
                <Button color="error" size="sm" onClick={onClose} className="dspl-info-modal__btn">
                    OK
                </Button>
            </div>
        </Modal>
    );
};

export default ErrorModal;
