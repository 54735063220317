import React from 'react';
import {Button, Modal} from 'reactstrap';

interface OwnProps {
    onClose(): void;
}

const SuccessfulModal = ({onClose}: OwnProps): JSX.Element => {
    return (
        <Modal isOpen={true} className="dspl-info-modal">
            <div className="dspl-info-modal__header">
                <div className="dspl-info-modal__title dspl-info-modal__title_success">Successfully Sent</div>
            </div>
            <div className="dspl-info-modal__content">
                Your message has been sent successfully, one of our team members will contact you soon. Have a nice day!
            </div>
            <div className="dspl-info-modal__footer">
                <Button color="success" size="sm" onClick={onClose} className="dspl-info-modal__btn">
                    OK
                </Button>
            </div>
        </Modal>
    );
};

export default SuccessfulModal;
