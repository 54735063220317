import * as types from 'app/actions/actionTypes';

export interface CommonDataState {
    modalData: {modalName: string; modalData: any} | undefined;
}

const defaultState: CommonDataState = {
    modalData: undefined,
};

export default (state = defaultState, action: types.AppActionTypes): CommonDataState => {
    switch (action.type) {
        case types.SHOW_MODAL: {
            return {
                ...state,
                modalData: action.payload,
            };
        }

        case types.HIDE_MODAL: {
            return {
                ...state,
                modalData: undefined,
            };
        }

        default:
            return state;
    }
};
