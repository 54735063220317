import SuccessfulModal from './SuccessfulModal';
import GetAccessModal from './GetAccessModal';
import SupportModal from './SupportModal';
import DeleteModal from './DeleteModal';
import ErrorModal from './ErrorModal';

export const allModalsNames = {
    successfulModal: 'SUCCESSFUL_MODAL',
    getAccessModal: 'GET_ACCESS_MODAL',
    supportModal: 'SUPPORT_MODAL',
    deleteModal: 'DELETE_MODAL',
    errorModal: 'ERROR_MODAL',
};

const allModalsMap = {
    [allModalsNames.successfulModal]: SuccessfulModal,
    [allModalsNames.getAccessModal]: GetAccessModal,
    [allModalsNames.supportModal]: SupportModal,
    [allModalsNames.deleteModal]: DeleteModal,
    [allModalsNames.errorModal]: ErrorModal,
};

export default allModalsMap;
