import React from 'react';
import {Formik, Field} from 'formik';
import {Button, Form as BtsForm, Row, Col} from 'reactstrap';
import * as Yup from 'yup';

import {getTypeFieldNameFactory} from 'utils/typeScript';
import FormTextInput from 'components/form/TextInput';
import Select from 'components/form/Select';

import {SupportFormValues} from 'pages/DispatchlandLandingPage/types';

interface FormProps {
    onSubmit(fields: SupportFormValues): void;
}

const questionList = [
    {value: '', label: 'please choose question topic', disabled: true},
    {value: 'Sales', label: 'Sales'},
    {value: 'Customer Support', label: 'Customer Support'},
    {value: 'Customer Training', label: 'Customer Training'},
    {value: 'Partnership', label: 'Partnership'},
];

const initialValues = {
    fullName: '',
    phoneNumber: '',
    email: '',
    companyName: '',
    questionTopic: '',
    message: '',
};

const validationSchema = Yup.object().shape({
    fullName: Yup.string()
        .required('please enter full name')
        .matches(/[A-Za-z.]+/, 'full name must contain letters, please retype it'),
    phoneNumber: Yup.string()
        .required('enter phone number')
        // eslint-disable-next-line no-magic-numbers
        .min(10, 'must be more 10 digits, please fix it')
        .matches(/^([0-9-()+ ]+[0-9]+)+/, 'phone number must contain digits, please retype it'),
    email: Yup.string().required('enter email address').email('enter correct email address'),
    companyName: Yup.string()
        .required('enter company name')
        .matches(/[A-Za-z.]+/, 'company name must contain letters, please retype it'),
    questionTopic: Yup.string().required('please choose question topic'),
    message: Yup.string(),
});

const getName = getTypeFieldNameFactory<SupportFormValues>();

const FormContent = ({formProps: {handleSubmit}, formProps}: any): JSX.Element => {
    return (
        <BtsForm onSubmit={handleSubmit} className="dspl-form">
            <div className="dspl-form__section">
                <Row>
                    <Col>
                        <h2>Personal Information:</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Field
                            maxLength={30}
                            label="Full Name"
                            name={getName('fullName')}
                            component={FormTextInput}
                            required={true}
                            placeholder="enter full name"
                        />
                    </Col>
                    <Col md={6}>
                        <Field
                            maxLength={20}
                            label="Phone Number"
                            name={getName('phoneNumber')}
                            component={FormTextInput}
                            required={true}
                            placeholder="enter phone number"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Field
                            maxLength={50}
                            label="Email"
                            name={getName('email')}
                            type="email"
                            component={FormTextInput}
                            required={true}
                            placeholder="enter email"
                        />
                    </Col>
                    <Col md={6}>
                        <Field
                            maxLength={30}
                            label="Company Name"
                            name={getName('companyName')}
                            component={FormTextInput}
                            required={true}
                            placeholder="enter company name"
                        />
                    </Col>
                </Row>
            </div>
            <div className="dspl-form__section">
                <Row>
                    <Col>
                        <h2>General Information:</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Field
                            label="Question Topic"
                            name={getName('questionTopic')}
                            component={Select}
                            setFieldValue={formProps.setFieldValue}
                            options={questionList}
                            required={true}
                            placeholder="choose question topic"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Field
                            maxLength={500}
                            label="Message:"
                            name={getName('message')}
                            type="textarea"
                            component={FormTextInput}
                            placeholder="enter message"
                        />
                    </Col>
                </Row>
            </div>
            <div className="dspl-form__footer">
                <Button type="submit" color="warning" size="lg">
                    Send
                </Button>
            </div>
        </BtsForm>
    );
};

const SupportForm = ({onSubmit}: FormProps): JSX.Element => {
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {(props) => {
                return <FormContent formProps={props} />;
            }}
        </Formik>
    );
};

export default SupportForm;
