import React, {useMemo} from 'react';
import classNames from 'classnames';

import {FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {getIn} from 'formik';

const Select = ({
    field,
    form: {errors, touched},
    label,
    options,
    setFieldValue,
    disabled,
    required,
    ...rest
}: any): JSX.Element => {
    const validationError = getIn(errors, field.name);
    const isTouched = Boolean(touched[field.name]);
    const isInvalid = Boolean(isTouched && validationError);

    const labelText = (
        <Label className="dspl-form__label" for={field.name}>
            {label}
            {required && <span className="dspl-form__label_required">*</span>}
        </Label>
    );

    const selectedValue = useMemo(() => {
        return options.find((item: any) => item.value === field.value)?.value;
    }, [field.value, options]);
    const handleChange = (e: any): void => {
        setFieldValue(field.name, e.target.value);
    };
    const customSelectClassNames = classNames('dspl-form__select', {placeholder: !selectedValue});

    if (isInvalid) {
        return (
            <FormGroup>
                {labelText}
                <Input
                    {...field}
                    {...rest}
                    id={field.name}
                    type="select"
                    disabled={disabled}
                    invalid
                    className={customSelectClassNames}
                >
                    {options.map((item: any, index: number) => (
                        <option key={index}>{item.label}</option>
                    ))}
                </Input>
                <FormFeedback>{validationError}</FormFeedback>
            </FormGroup>
        );
    }

    return (
        <FormGroup>
            {labelText}
            <Input
                {...rest}
                id={field.name}
                type="select"
                value={selectedValue}
                onChange={handleChange}
                className={customSelectClassNames}
            >
                {options.map((item: any, index: number) => (
                    <option key={index} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

export default Select;
