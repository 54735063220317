import React, {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import TagManager from 'react-gtm-module';
import {useHistory, useLocation} from 'react-router-dom';

import {GetAccessFormValues} from 'pages/DispatchlandLandingPage/types';
import * as actions from 'pages/DispatchlandLandingPage/actions';

import useShowModalsActions from 'hooks/useShowModalsActions';

import {initialize} from 'config/index';

import ModalProvider from '../components/modals/ModalProvider';
import Router from '../router';

const App = (): JSX.Element => {
    const {showDeleteModal} = useShowModalsActions();
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const showDeleteModalHandler = useCallback(() => {
        showDeleteModal((data: GetAccessFormValues): void => {
            dispatch(actions.getAccessInfo(data));
            history.push('/');
        });
    }, [dispatch, showDeleteModal]);

    useEffect(() => {
        TagManager.initialize(initialize);
    }, []);

    useEffect(() => {
        if (pathname === '/delete-account') {
            showDeleteModalHandler();
        }
    }, [pathname]);

    return (
        <div className="dspl-landing-page">
            <Router />
            <ModalProvider />
        </div>
    );
};

export default App;
