import {useDispatch} from 'react-redux';
import TagManager from 'react-gtm-module';

import {showModal} from 'app/actions';

import {allModalsNames} from 'components/modals/modalsMap';
import {openGetAccessModal, openGetSupportModal} from 'config/index';

export default function (): any {
    const dispatch = useDispatch();

    return {
        showSupportModal: (onSubmit: any): void => {
            const params = {
                name: allModalsNames.supportModal,
                data: {},
                onSubmit,
            };
            TagManager.dataLayer(openGetSupportModal);
            dispatch(showModal(params));
        },

        showGetAccessModal: (onSubmit: any): void => {
            const params = {
                name: allModalsNames.getAccessModal,
                data: {},
                onSubmit,
            };

            TagManager.dataLayer(openGetAccessModal);
            dispatch(showModal(params));
        },

        showDeleteModal: (onSubmit: any): void => {
            const params = {
                name: allModalsNames.deleteModal,
                data: {},
                onSubmit,
            };

            TagManager.dataLayer(openGetAccessModal);
            dispatch(showModal(params));
        },
    };
}
