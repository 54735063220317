import React from 'react';
import {getIn} from 'formik/dist';
import {FormGroup, Label, Input, FormFeedback} from 'reactstrap';

const TextInput = ({
    field,
    form: {errors, touched},
    label,
    type,
    disabled,
    placeholder,
    required,
    ...rest
}: any): JSX.Element => {
    const validationError = getIn(errors, field.name);
    const isTouched = Boolean(touched[field.name]);
    const isInvalid = Boolean(isTouched && validationError);
    const labelText = (
        <Label className="dspl-form__label" for={field.name}>
            {label}
            {required && <span className="dspl-form__label_required">*</span>}
        </Label>
    );
    if (isInvalid) {
        return (
            <FormGroup>
                {labelText}
                <Input
                    {...field}
                    id={field.name}
                    type={type}
                    disabled={disabled}
                    placeholder={placeholder}
                    {...rest}
                    invalid
                />
                <FormFeedback>{validationError}</FormFeedback>
            </FormGroup>
        );
    }

    return (
        <FormGroup>
            {labelText}
            <Input {...field} id={field.name} type={type} disabled={disabled} placeholder={placeholder} {...rest} />
        </FormGroup>
    );
};

export default TextInput;
