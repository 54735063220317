import React from 'react';
import {connect} from 'react-redux';
import isFunction from 'lodash/isFunction';

import * as appActions from 'app/actions';

import globalRegister from 'utils/globalRegister';

import SuccessfulModal from '../SuccessfulModal';
import GetAccessModal from '../GetAccessModal';
import {allModalsNames} from '../modalsMap';
import SupportModal from '../SupportModal';
import DeleteModal from '../DeleteModal';
import ErrorModal from '../ErrorModal';

const ModalContainer = ({modalData, onClose}: any): JSX.Element | null => {
    if (!modalData) {
        return null;
    }
    const {name, closeBeforeSubmit = true} = modalData;
    const onSubmitHandler = globalRegister.getHandler();
    const onSubmit = (fields: any): void => {
        if (closeBeforeSubmit) {
            onClose();
        }
        if (isFunction(onSubmitHandler)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmitHandler(fields);
        }
    };

    if (name === allModalsNames.supportModal) {
        return <SupportModal onSubmit={onSubmit} onClose={onClose} />;
    }

    if (name === allModalsNames.getAccessModal) {
        return <GetAccessModal onSubmit={onSubmit} onClose={onClose} />;
    }

    if (name === allModalsNames.deleteModal) {
        return <DeleteModal onSubmit={onSubmit} onClose={onClose} />;
    }

    if (name === allModalsNames.successfulModal) {
        return <SuccessfulModal onClose={onClose} />;
    }

    if (name === allModalsNames.errorModal) {
        return <ErrorModal onClose={onClose} />;
    }

    return null;
};

export default connect(
    (state) => {
        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            modalData: state.app.modalData,
        };
    },
    (dispatch) => {
        return {
            onClose: () => dispatch(appActions.hideModal()),
        };
    },
)(ModalContainer);
