import {SupportFormValues, GetAccessFormValues} from 'pages/DispatchlandLandingPage/types';

export const SET_SUPPORT_INFO = 'SET_SUPPORT_INFO' as const;
export const SET_ACCESS_INFO = 'SET_ACCESS_INFO' as const;

interface SetSupportInfo {
    type: typeof SET_SUPPORT_INFO;
    payload: {data: SupportFormValues};
}

interface SetAccessInfo {
    type: typeof SET_ACCESS_INFO;
    payload: {data: GetAccessFormValues};
}

export type SupportActionTypes = SetSupportInfo;

export type AccessActionTypes = SetAccessInfo;
